var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tw-h-full" }, [
    _c("div", { staticClass: "tw-flex tw-flex-col tw-h-full" }, [
      _vm._m(0),
      _c(
        "div",
        { staticStyle: { height: "calc(100% - 48px)" } },
        [
          _c("PeTAT", {
            attrs: {
              response: _vm.response,
              isLoading: _vm.isLoading,
              legendaType: _vm.legendaType
            },
            on: {
              initialize: _vm.initializeEarthquakes,
              "update:legendaType": function($event) {
                _vm.legendaType = $event
              },
              "update:legenda-type": function($event) {
                _vm.legendaType = $event
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h2", { staticClass: "elementSubTitle" }, [_vm._v("PeTAT®")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }