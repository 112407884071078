import axios, { AxiosError, AxiosResponse } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Token } from '@/support/Token';
import { identifyHostname } from '@/support/Client';
import { DateTime, Settings } from 'luxon';
import PeTAT from '@/components/PeTAT/PeTAT.vue';
import { PetatLegendaTypes } from '@/components/PeTAT/PeTAT';

@Component<Effectgebied>({
  components: {
    PeTAT,
  },
})
export default class Effectgebied extends Vue {
  public $pageTitle = 'Effectgebied';

  protected isLoading = false;

  public oAuthServer: string = identifyHostname() || '';

  protected response: any | null = null;

  protected legendaType = PetatLegendaTypes.MAX_PGV;

  public async mounted() {
    Settings.defaultLocale = 'nl';
    DateTime.local().startOf('month');

    this.initializeBreadcrumb();
  }

  protected initializeBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Effectgebied' }],
    });
  }

  protected async initializeEarthquakes(payload: any) {
    this.isLoading = true;

    const client = this.getAxiosClient();

    await client
      .get(`/v1/tools/effectgebied?${payload}`)
      .then((response: AxiosResponse) => {
        this.response = response;
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      // this.earthquakes = [];
      // this.isLoading = false;
      // this.showMap = false;
      });
  }

  protected getAxiosClient() {
    const headers = {};
    if (Token.get('access')) {
      (headers as any).Authorization = `Bearer ${Token.get('access')}`;
    }
    const axiosClient = axios.create({
      baseURL: this.oAuthServer,
      headers,
    });
    return axiosClient;
  }

  // Getters
  protected get dialogOptionsCreated(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Trillings pdf is aangemaakt.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Oke',
        },
      },
    };
  }
}
